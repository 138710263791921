var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-tabs',{attrs:{"dark":"","grow":"","color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"secondary"}}),_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item,attrs:{"to":`/main/overviews/${item}`}},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('v-container',{staticClass:"pa-3",attrs:{"fluid":""}},[_c('v-card',{staticClass:"primary mb-3"},[_c('v-card-title',[_c('v-layout',{staticClass:"white--text",attrs:{"fill-height":"","align-center":"","justify-start":"","row":"","wrap":""}},[_c('v-flex',{staticClass:"ocp-bullets mr-3",attrs:{"shrink":""}}),_c('v-flex',{attrs:{"grow":"","mr-1":""}},[_vm._v("VENTO")])],1)],1),_vm._l((_vm.windStations),function(station){return _c('div',{key:station.id},[_c('v-card-text',_vm._l((station.equipments),function(equipment){return _c('v-layout',{key:equipment.id,attrs:{"row":"","wrap":"","fill-height":""}},[(equipment.active && equipment.latestData && equipment.latestData.wind_data)?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 grey darken-2 rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"subheading font-weight-bold white--text"},[_vm._v(" "+_vm._s(station.terminal)+" - "+_vm._s(station.location)+" "),_c('v-btn',{attrs:{"small":"","icon":"","dark":"","to":{ name: 'main-equipments-show', params: { id: equipment.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("insights")])],1)],1),_c('v-flex',{staticClass:"caption font-weight-bold"},[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('updated'))+" "+_vm._s(_vm._f("moment")(equipment.latestData.wind_data.created_at,'from', 'now'))+" ")])]}}],null,true)},[_c('span',[_vm._v(" Data de medição: "+_vm._s(_vm._f("moment")(equipment.latestData.wind_data.measured_at,"D MMM YYYY, H:mm:ss"))+" ")])])],1)],1),_c('v-spacer'),(equipment.maintenance)?_c('v-icon',{staticClass:"icon-maintenance mr-2"}):_vm._e(),_c('v-icon',{staticClass:"icon-wind"})],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.wind_data &&
                  equipment.latestData.wind_data.wind_speed !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v("Velocidade")]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("fromMpStoKT")(equipment.latestData.wind_data.wind_speed)))+" "+_vm._s(_vm.$tc('knots', equipment.latestData.wind_data.wind_speed))+" ")])],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.wind_data &&
                  equipment.latestData.wind_data.gust_speed !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v("Rajada")]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("fromMpStoKT")(equipment.latestData.wind_data.gust_speed)))+" "+_vm._s(_vm.$tc('knots', equipment.latestData.wind_data.gust_speed))+" ")])],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.wind_data &&
                  equipment.latestData.wind_data.wind_direction !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v("Direção")]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(equipment.latestData.wind_data.wind_direction))+" ° "),_c('v-icon',{staticClass:"white--text",style:('transform: rotate(' +
                          ((equipment.latestData.wind_data.wind_direction + 180) % 360) +
                          'deg); '),attrs:{"medium":""}},[_vm._v("north")])],1)],1)],1)],1):_vm._e()],1)}),1)],1)})],2),_c('v-card',{staticClass:"primary mb-3"},[_c('v-card-title',[_c('v-layout',{staticClass:"white--text",attrs:{"fill-height":"","align-center":"","justify-start":"","row":"","wrap":""}},[_c('v-flex',{staticClass:"ocp-bullets mr-3",attrs:{"shrink":""}}),_c('v-flex',{attrs:{"grow":"","mr-1":""}},[_vm._v("CORRENTE")])],1)],1),_vm._l((_vm.currentStations),function(station){return _c('div',{key:station.id},[_c('v-card-text',_vm._l((station.equipments),function(equipment){return _c('v-layout',{key:equipment.id,attrs:{"row":"","wrap":"","fill-height":""}},[(equipment.active && equipment.latestData && equipment.latestData.current_data)?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 grey darken-2 rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"subheading font-weight-bold white--text"},[_vm._v(" "+_vm._s(station.terminal)+" - "+_vm._s(station.location)+" "),_c('v-btn',{attrs:{"small":"","icon":"","dark":"","to":{ name: 'main-equipments-show', params: { id: equipment.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("insights")])],1)],1),_c('v-flex',{staticClass:"caption font-weight-bold"},[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('updated'))+" "+_vm._s(_vm._f("moment")(equipment.latestData.current_data.created_at,'from', 'now'))+" ")])]}}],null,true)},[_c('span',[_vm._v(" Data de medição: "+_vm._s(_vm._f("moment")(equipment.latestData.current_data.measured_at,"D MMM YYYY, H:mm:ss"))+" ")])])],1)],1),_c('v-spacer'),(equipment.maintenance)?_c('v-icon',{staticClass:"icon-maintenance mr-2"}):_vm._e(),_c('v-icon',{staticClass:"icon-current"})],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.current_data &&
                  equipment.latestData.current_data.current_speed !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v("Velocidade resultante")]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("fromMpStoKT")(equipment.latestData.current_data.current_speed)))+" "+_vm._s(_vm.$tc('knots', equipment.latestData.current_data.current_speed))+" ")])],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.current_data &&
                  equipment.latestData.current_data.current_direction !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v("Direção resultante")]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(equipment.latestData.current_data.current_direction))+" ° "),_c('v-icon',{staticClass:"white--text",style:('transform: rotate(' +
                          equipment.latestData.current_data.current_direction +
                          'deg); '),attrs:{"medium":""}},[_vm._v("north")])],1)],1)],1)],1):_vm._e()],1)}),1)],1)})],2),_c('v-card',{staticClass:"primary mb-3"},[_c('v-card-title',[_c('v-layout',{staticClass:"white--text",attrs:{"fill-height":"","align-center":"","justify-start":"","row":"","wrap":""}},[_c('v-flex',{staticClass:"ocp-bullets mr-3",attrs:{"shrink":""}}),_c('v-flex',{attrs:{"grow":"","mr-1":""}},[_vm._v("ONDA")])],1)],1),_vm._l((_vm.waveStations),function(station){return _c('div',{key:station.id},[_c('v-card-text',_vm._l((station.equipments),function(equipment){return _c('v-layout',{key:equipment.id,attrs:{"row":"","wrap":"","fill-height":""}},[(equipment.active && equipment.latestData && equipment.latestData.wave_data)?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 grey darken-2 rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"subheading font-weight-bold white--text"},[_vm._v(" "+_vm._s(station.terminal)+" - "+_vm._s(station.location)+" "),_c('v-btn',{attrs:{"small":"","icon":"","dark":"","to":{ name: 'main-equipments-show', params: { id: equipment.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("insights")])],1)],1),_c('v-flex',{staticClass:"caption font-weight-bold"},[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('updated'))+" "+_vm._s(_vm._f("moment")(equipment.latestData.wave_data.created_at,'from', 'now'))+" ")])]}}],null,true)},[_c('span',[_vm._v(" Data de medição: "+_vm._s(_vm._f("moment")(equipment.latestData.wave_data.measured_at,"D MMM YYYY, H:mm:ss"))+" ")])])],1)],1),_c('v-spacer'),(equipment.maintenance)?_c('v-icon',{staticClass:"icon-maintenance mr-2"}):_vm._e(),_c('v-icon',{staticClass:"icon-wave"})],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.wave_data &&
                  equipment.latestData.wave_data.significant_wave_height !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v("Altura (H"),_c('sub',[_vm._v("s")]),_vm._v(")")]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(equipment.latestData.wave_data.significant_wave_height))+" m ")])],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.wave_data &&
                  equipment.latestData.wave_data.peak_wave_period !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v("Período (T"),_c('sub',[_vm._v("p")]),_vm._v(")")]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(equipment.latestData.wave_data.peak_wave_period))+" s ")])],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.wave_data &&
                  equipment.latestData.wave_data.peak_wave_direction !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v("Direção (D"),_c('sub',[_vm._v("p")]),_vm._v(")")]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(equipment.latestData.wave_data.peak_wave_direction))+" ° "),_c('v-icon',{staticClass:"white--text",style:('transform: rotate(' +
                          ((equipment.latestData.wave_data.peak_wave_direction + 180) % 360) +
                          'deg); '),attrs:{"medium":""}},[_vm._v("north")])],1)],1)],1)],1):_vm._e()],1)}),1)],1)})],2),_c('v-card',{staticClass:"primary mb-3"},[_c('v-card-title',[_c('v-layout',{staticClass:"white--text",attrs:{"fill-height":"","align-center":"","justify-start":"","row":"","wrap":""}},[_c('v-flex',{staticClass:"ocp-bullets mr-3",attrs:{"shrink":""}}),_c('v-flex',{attrs:{"grow":"","mr-1":""}},[_vm._v("MARÉ")])],1)],1),_vm._l((_vm.tideStations),function(station){return _c('div',{key:station.id},[_c('v-card-text',_vm._l((station.equipments),function(equipment){return _c('v-layout',{key:equipment.id,attrs:{"row":"","wrap":"","fill-height":""}},[(equipment.active && equipment.latestData && equipment.latestData.tide_data)?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 grey darken-2 rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"subheading font-weight-bold white--text"},[_vm._v(" "+_vm._s(station.terminal)+" - "+_vm._s(station.location)+" "),_c('v-btn',{attrs:{"small":"","icon":"","dark":"","to":{ name: 'main-equipments-show', params: { id: equipment.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("insights")])],1)],1),_c('v-flex',{staticClass:"caption font-weight-bold"},[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('updated'))+" "+_vm._s(_vm._f("moment")(equipment.latestData.tide_data.created_at,'from', 'now'))+" ")])]}}],null,true)},[_c('span',[_vm._v(" Data de medição: "+_vm._s(_vm._f("moment")(equipment.latestData.tide_data.measured_at,"D MMM YYYY, H:mm:ss"))+" ")])])],1)],1),_c('v-spacer'),(equipment.maintenance)?_c('v-icon',{staticClass:"icon-maintenance mr-2"}):_vm._e(),_c('v-icon',{staticClass:"icon-tide"})],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.tide_data &&
                  equipment.latestData.tide_data.water_level !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v(" Nível ")]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(equipment.latestData.tide_data.water_level))+" m ")])],1)],1)],1):_vm._e()],1)}),1)],1)})],2),_c('v-card',{staticClass:"primary mb-3"},[_c('v-card-title',[_c('v-layout',{staticClass:"white--text",attrs:{"fill-height":"","align-center":"","justify-start":"","row":"","wrap":""}},[_c('v-flex',{staticClass:"ocp-bullets mr-3",attrs:{"shrink":""}}),_c('v-flex',{attrs:{"grow":"","mr-1":""}},[_vm._v("TEMPO")])],1)],1),_vm._l((_vm.weatherStations),function(station){return _c('div',{key:station.id},[_c('v-card-text',_vm._l((station.equipments),function(equipment){return _c('v-layout',{key:equipment.id,attrs:{"row":"","wrap":"","fill-height":""}},[(
                equipment.active && equipment.latestData &&
                  equipment.latestData.weather_data &&
                  (equipment.latestData.weather_data.precipitation !== null ||
                    equipment.latestData.weather_data.visibility !== null ||
                    equipment.latestData.weather_data.water_temperature !== null)
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 grey darken-2 rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"subheading font-weight-bold white--text"},[_vm._v(" "+_vm._s(station.terminal)+" - "+_vm._s(station.location)+" "),_c('v-btn',{attrs:{"small":"","icon":"","dark":"","to":{ name: 'main-equipments-show', params: { id: equipment.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("insights")])],1)],1),_c('v-flex',{staticClass:"caption font-weight-bold"},[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('updated'))+" "+_vm._s(_vm._f("moment")(equipment.latestData.weather_data.created_at,'from', 'now'))+" ")])]}}],null,true)},[_c('span',[_vm._v(" Data de medição: "+_vm._s(_vm._f("moment")(equipment.latestData.weather_data.measured_at,"D MMM YYYY, H:mm:ss"))+" ")])])],1)],1),_c('v-spacer'),(equipment.maintenance)?_c('v-icon',{staticClass:"icon-maintenance mr-2"}):_vm._e(),_c('v-icon',{class:{
                      'icon-precipitation':
                        equipment.latestData.weather_data.precipitation !== null,
                      'icon-visibility': equipment.latestData.weather_data.visibility !== null,
                      'icon-temperature':
                        equipment.latestData.weather_data.water_temperature !== null,
                    }})],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.weather_data &&
                  equipment.latestData.weather_data.visibility !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v("Visibilidade")]),_c('v-flex',{staticClass:"headline white--text text-xs-right text-truncate"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("fromMtoKM")(equipment.latestData.weather_data.visibility)))+" km "),_c('span',{staticClass:"body-1"},[_vm._v(" ("+_vm._s(_vm._f("formatNumber")(_vm._f("fromMtoNM")(equipment.latestData.weather_data.visibility)))+" nm) ")])])],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.weather_data &&
                  equipment.latestData.weather_data.precipitation !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v("Precipitação")]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(equipment.latestData.weather_data.precipitation))+" mm ")])],1)],1)],1):_vm._e(),(
                equipment.active && equipment.latestData &&
                  equipment.latestData.weather_data &&
                  equipment.latestData.weather_data.water_temperature !== null
              )?_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-card',{staticClass:"pa-2 accent rounded ocp-shadow",class:{
                  'mb-3': _vm.$vuetify.breakpoint.smAndDown,
                  'mr-3': _vm.$vuetify.breakpoint.mdAndUp,
                  'secondary--text': station.terminal === 'T1',
                  'warning--text': station.terminal === 'T2',
                }},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('equip.water_temp')))]),_c('v-flex',{staticClass:"headline white--text text-xs-right no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(equipment.latestData.weather_data.water_temperature))+" °C ")])],1)],1)],1):_vm._e()],1)}),1)],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }