
import { store } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import { dispatchGetStations } from '@/store/stations/actions';
import { dispatchGetAllLatestData } from '@/store/data/actions';
import { readAllLatestData } from '@/store/data/getters';
import { readStations } from '@/store/stations/getters';
import { apiWSUrl } from '@/env';
import { dispatchUpdateLatestData } from '@/store/overviews/actions';
import { getLocalToken } from '@/utils';
import { readUserRoles } from '@/store/main/getters';
import { ICurrentData, ITideData, IWaveData, IWeatherData, IWindData } from '@/interfaces';

const routeGuardMain = async (to, from, next) => {
  if (!['VTS', 'ADMIN'].some((r) => readUserRoles(store)?.includes(r))) {
    next('/main');
  } else {
    next();
  }
};

const connect = (ctx) => {

  const ws = new WebSocket(`${apiWSUrl}/api/v1/data/ws?token=${ctx.token}`);

  ws.onmessage = async (event) => {
    const latestData = ctx.latestData
    const receivedMessage: IWindData | IWeatherData | ICurrentData | IWaveData | ITideData = JSON.parse(event.data)
    const eqpId = Object.values(receivedMessage)[0].equipment_id
    latestData[eqpId] = receivedMessage
    await dispatchUpdateLatestData(ctx.$store, latestData);
  };

  ws.onclose = (e) => {
    setTimeout(() => connect(ctx), 1000);
  };

  ws.onerror = (e) => {
    ws.close();
  };

  return ws;
};

@Component({
  filters: {
    formatNumber(n: number) {
      return Math.round(n * 100) / 100;
    },
  },
})
export default class VTS extends Vue {
  public tab = null;
  private connection;

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get tabs() {
    const lot: string[] = [];
    const roles = ['T1', 'T2', 'VTS'];
    roles.forEach((role) => {
      if ([role, 'ADMIN'].some((r) => readUserRoles(store)?.includes(r))) {
        lot.push(role);
      }
    });
    return lot;
  }

  get latestData() {
    return readAllLatestData(this.$store);
  }

  get windStations() {
    return this.stationsOfCategory('Wind');
  }

  get waveStations() {
    return this.stationsOfCategory('Wave');
  }

  get tideStations() {
    return this.stationsOfCategory('Tide');
  }

  get currentStations() {
    return this.stationsOfCategory('Current');
  }

  get weatherStations() {
    return this.stationsOfCategory('Weather');
  }

  get stations() {
    return readStations(this.$store);
  }

  get token() {
    return getLocalToken();
  }

  public async mounted() {
    await dispatchGetStations(this.$store);
    await dispatchGetAllLatestData(this.$store);
  }

  public async created() {
    const ctx = this;
    this.connection = connect(ctx);
  }

  public async beforeRouteLeave(to, from, next) {
    this.connection.close();
    next();
  }

  private stationsOfCategory(category) {
    const stations = this.stations
      .filter((s) => s.equipments.some((e) => e.categories.map((c) => c.name).includes(category)))
      .sort((a, b) => (a.terminal > b.terminal ? 1 : a.terminal === b.terminal ? 0 : -1));
    stations.forEach((st, is) =>
      st.equipments.forEach(
        (eq, ie) => (stations[is].equipments[ie].latestData = this.latestData[eq.id]),
      ),
    );
    return stations;
  }
}
